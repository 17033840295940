import React, { useEffect, useState } from 'react';
import useCartContant from '../Mainpage/useCartContant';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Select from 'react-select';
import { Country, State, City } from 'country-state-city';
import useGetAddress from './useGetAddress';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { Image } from 'react-bootstrap';
import razorpay from '../../images/razorpay.png'
import paypal from '../../images/paypal.png'
import payoneer from '../../images/payoneer.png'
import axios from 'axios';

const CheckOut = () => {

    const navigate = useNavigate();

    const { cartproducts, loading, error, fetchCartProducts } = useCartContant();
    const { Address, load, err, fetchAddress } = useGetAddress();
    const [phone, setPhone] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [update, setUpdate] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState('');
    const [fastD, setFastD] = useState(false);
    const [payType, setPayType] = useState(null);
    const [formData, setFormData] = useState({
        user_id: localStorage.getItem('user_id'),
        name: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
        contact_no: ''
    });

    const check = async () => {

        const token = localStorage.getItem('token');

        try {
            const response = await fetch('https://backend.amjgems.com/api/user-check', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify({ user_id: localStorage.getItem('user_id') }),
            });
            const data = await response.json();

        } catch (error) {
            navigate('/');
        }
    }
    useEffect(() => {
        check();
        fetchCartProducts();
        fetchAddress();

    }, []);

    useEffect(() => {
        if (Address) {
            setUpdate(true);
            setFormData({
                ...formData,
                name: Address.name || '',
                address_line_1: Address.address_line_1 || '',
                address_line_2: Address.address_line_2 || '',
                city: Address.city || '',
                state: Address.state || '',
                country: Address.country || '',
                pincode: Address.pincode || '',
                contact_no: Address.contact_no || ''
            });
            setPhone(Address.contact_no || '');

            const countryOption = Country.getAllCountries().find(country => country.name === Address.country);
            setSelectedCountry(countryOption ? { value: countryOption.isoCode, label: countryOption.name } : null);

            if (countryOption) {
                const statesList = State.getStatesOfCountry(countryOption.isoCode);
                setStates(statesList.map(state => ({
                    value: state.isoCode,
                    label: state.name
                })));

                const stateOption = statesList.find(state => state.name === Address.state);
                setSelectedState(stateOption ? { value: stateOption.isoCode, label: stateOption.name } : null);

                if (stateOption) {
                    const citiesList = City.getCitiesOfState(countryOption.isoCode, stateOption.isoCode);
                    setCities(citiesList.map(city => ({
                        value: city.name,
                        label: city.name
                    })));

                    const cityOption = citiesList.find(city => city.name === Address.city);
                    setSelectedCity(cityOption ? { value: cityOption.isoCode, label: cityOption.name } : null);
                }
            }
        }
        else {
            setUpdate(false);
            setFormData({
                ...formData,
                name: '',
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: '',
                country: '',
                pincode: '',
                contact_no: ''
            });
            setPhone('');
            setSelectedCity(null);
            setSelectedState(null);
            setSelectedCountry(null);
            setStates([]);
            setCities([]);

        }
    }, [Address]);

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        const statesList = State.getStatesOfCountry(selectedOption.value);
        setStates(statesList.map(state => ({
            value: state.isoCode,
            label: state.name
        })));
        setCities([]);
        setFormData(prevData => ({
            ...prevData,
            country: selectedOption.label
        }));
    };

    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        const citiesList = City.getCitiesOfState(selectedCountry.value, selectedOption.value);
        setCities(citiesList.map(city => ({
            value: city.name,
            label: city.name
        })));
        setFormData(prevData => ({
            ...prevData,
            state: selectedOption.label
        }));
    };

    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
        setFormData(prevData => ({
            ...prevData,
            city: selectedOption.label
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handlePhoneChange = (value) => {
        setPhone(value);
        setFormData(prevData => ({
            ...prevData,
            contact_no: value
        }));
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        // Example API endpoint
        const apiEndpoint = 'https://backend.amjgems.com/api/address/store';

        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Success:', result);
                fetchAddress();
                Swal.fire({
                    title: 'Address Added Successful!',
                })
                // Handle success (e.g., redirect or show a success message)
            } else {
                const error = await response.json();
                console.error('Error:', error);
                // Handle error (e.g., show an error message)
            }
        } catch (error) {
            console.error('Network error:', error);
            // Handle network error (e.g., show an error message)
        }
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        // Example API endpoint
        const apiEndpoint = 'https://backend.amjgems.com/api/address/delete';

        try {
            const response = await fetch(apiEndpoint, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify({ user_id: user_id })
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Success:', result);
                fetchAddress();
                setUpdate(false);
                Swal.fire({
                    title: 'Address Deleted Successful!',
                })
                // Handle success (e.g., redirect or show a success message)
            } else {
                const error = await response.json();
                console.error('Error:', error);
                // Handle error (e.g., show an error message)
            }
        } catch (error) {
            console.error('Network error:', error);
            // Handle network error (e.g., show an error message)
        }
    }

    const handleFastShippingChange = (event) => {
        const isChecked = event.target.checked; // Get the checkbox status
        setFastD(isChecked); // Set fastD based on checkbox status
        console.log(isChecked);
        const s = selectedCoupon ? selectedCoupon.id : ''
        fetchCartProducts(s, isChecked);
    }

    const applyCoupon = (e) => {
        const s = selectedCoupon ? selectedCoupon.id : ''
        console.log(fastD);
        fetchCartProducts(s, fastD);
    }

    const handlePayType = (e) => {
        setPayType(e.target.id);
    }

    const handleRazorpayPayment = async () => {
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        try {
            const razorpayResponse = await axios.post('/api/create-razorpay-order', {
                user_id: user_id,
            });

            const { key, order_id, amount, currency } = razorpayResponse.data;

            const options = {
                key: key,
                amount: amount * 100,
                currency: currency,
                name: 'Your Company Name',
                description: 'Order Payment',
                order_id: order_id,
                handler: async function (response) {
                    // Payment successful, send details to backend for verification
                    await axios.post('/api/verify-razorpay-payment', {
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_signature: response.razorpay_signature,
                    });
                },
                prefill: {
                    name: 'Customer Name',
                    email: 'customer@example.com',
                    contact: '9999999999',
                },
                theme: {
                    color: '#3399cc',
                },
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
        } catch (error) {
            console.error('Error processing Razorpay payment:', error);
        }
    };


    const placePayment = async (order_id) => {

        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        // Example API endpoint
        const apiEndpoint = 'https://backend.amjgems.com/api/create-paypal-payment';

        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify({ user_id: user_id, order_id: order_id ? order_id : '' })
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Success:', result);
                // Redirect to the PayPal URL
                const paypalUrl = result.paypal_url;
                window.location.href = paypalUrl;
                // Handle success (e.g., redirect or show a success message)
            } else {
                const error = await response.json();
                console.error('Error:', error);
                // Handle error (e.g., show an error message)
            }
        } catch (error) {
            console.error('Network error:', error);
            // Handle network error (e.g., show an error message)
        }
    }

    const submitPay = async (e) => {
        if (payType !== null) {
            if (Address) {

                e.preventDefault();
                const token = localStorage.getItem('token');
                const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
                let shipping = null;
                if (fastD) {
                    shipping = 'fast_shipping';

                }
                else {
                    if (cartproducts?.free_shipping) {
                        shipping = 'free_shipping';
                    }
                    else {
                        shipping = 'standard_shipping';
                    }

                }
                // Example API endpoint
                const apiEndpoint = 'https://backend.amjgems.com/api/place-order';

                try {
                    const response = await fetch(apiEndpoint, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}` // Using Bearer token authentication
                        },
                        body: JSON.stringify({ user_id: user_id, payment_method: payType, coupon_id: selectedCoupon ? selectedCoupon.id : '', shipping_type: shipping, fast_delivery: fastD })
                    });

                    if (response.ok) {
                        const result = await response.json();
                        console.log('Success:', result);
                        placePayment(result.order_id)
                        // Handle success (e.g., redirect or show a success message)
                    } else {
                        const error = await response.json();
                        console.error('Error:', error);
                        // Handle error (e.g., show an error message)
                    }
                } catch (error) {
                    console.error('Network error:', error);
                    // Handle network error (e.g., show an error message)
                }
            }
            else {
                Swal.fire({
                    title: 'Add Address !',
                })
            }
        }
        else {
            Swal.fire({
                title: 'Select Payment Option !',
            })
        }
    }


    const leftColClassName = update ? 'none' : 'left-col';
    const leftColClass = update ? 'left-col' : 'none';

    if (load) {
        return <Loader />;
    }
    if (err) {
        return <div className="error">Error: {err.message}</div>;
    }


    return (
        <div className="container-checkout" style={{ padding: '16px' }}>
            {/* Shipping Information */}
            <div className={leftColClassName} style={{ flex: '1.7' }}>
                <section className="checkout-shipping-info">
                    <h2 className="section-title">Shipping Information</h2>
                    <form className="shipping-form" onSubmit={handleSubmit}>
                        <label htmlFor="name" className="form-label">Full Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-input"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />

                        <label htmlFor="address_line_1" className="form-label">Address Line 1 :</label>
                        <input
                            type="text"
                            id="address_line_1"
                            name="address_line_1"
                            className="form-input"
                            value={formData.address_line_1}
                            onChange={handleInputChange}
                            required
                        />

                        <label htmlFor="address_line_2" className="form-label">Address Line 2 :</label>
                        <input
                            type="text"
                            id="address_line_2"
                            name="address_line_2"
                            className="form-input"
                            value={formData.address_line_2}
                            onChange={handleInputChange}
                            required
                        />

                        <label htmlFor="country" className="form-label">Country:</label>
                        <Select
                            id="country"
                            name="country"
                            options={Country.getAllCountries().map(country => ({
                                value: country.isoCode,
                                label: country.name
                            }))}
                            className="form-select"
                            onChange={handleCountryChange}
                            value={selectedCountry}
                        />

                        <label htmlFor="state" className="form-label">State:</label>
                        <Select
                            id="state"
                            name="state"
                            options={states}
                            className="form-select"
                            onChange={handleStateChange}
                            value={selectedState}
                        />

                        <label htmlFor="city" className="form-label">City:</label>
                        <Select
                            id="city"
                            name="city"
                            options={cities}
                            className="form-select"
                            onChange={handleCityChange}
                            value={selectedCity}
                        />


                        <label htmlFor="pincode" className="form-label">Pincode/ZIP Code:</label>
                        <input
                            type="text"
                            id="pincode"
                            name="pincode"
                            className="form-input"
                            value={formData.pincode}
                            onChange={handleInputChange}
                            required
                        />

                        <label htmlFor="contact_no" className="form-label">Contact Number :</label>
                        <PhoneInput
                            placeholder="Enter phone number"
                            value={phone}
                            className="form-input"
                            onChange={handlePhoneChange}
                        />
                        <button type="submit" className="add_address_button">Add Address</button>
                    </form>
                </section>
            </div>
            <div className={leftColClass} style={{ flex: '1.7' }}>
                <section className="checkout-shipping-info">
                    <h2 className="section-title">Shipping Information</h2>
                    <div className="shipping-info">
                        <div className="info-item">
                            <strong>Full Name:</strong> {formData.name}
                        </div>
                        <div className="info-item">
                            <strong>Address_line_1:</strong> {formData.address_line_1}
                        </div>
                        <div className="info-item">
                            <strong>Address_line_2:</strong> {formData.address_line_2}
                        </div>
                        <div className="info-item">
                            <strong>City:</strong> {formData.city}
                        </div>
                        <div className="info-item">
                            <strong>State:</strong> {formData.state}
                        </div>
                        <div className="info-item">
                            <strong>Country:</strong> {formData.country}
                        </div>
                        <div className="info-item">
                            <strong>Pincode/ZIP Code:</strong> {formData.pincode}
                        </div>
                        <div className="info-item">
                            <strong>Contact Number:</strong> {phone}
                        </div>
                        <button
                            type="button"
                            onClick={() => setUpdate(false)}
                            className="add_address_button">
                            Update Address
                        </button>
                        <button
                            type="button"
                            onClick={handleDelete}
                            className="add_address_button">
                            Delete Address
                        </button>
                    </div>
                </section>
            </div>

            <div className="right-col" style={{ flex: '1.2' }}>
                {/* Order Summary */}
                <section className="checkout-order-summary">
                    <h2 className="section-title">Order Summary</h2>
                    {cartproducts.cart_items.map((cartproduct, index) => (
                        <div key={index}>
                            <ul className="order-list">
                                <div className="total" style={{ fontSize: '.9rem' }}>{cartproduct.product.product_name}</div>
                                <div className="price">
                                    $ {cartproduct.variant.price * (1 - cartproduct.product.product_discount / 100)} * {cartproduct.product_cart_quantity} =
                                    <span style={{ fontWeight: 'bold' }}> $ {cartproduct.product_total_price}</span>
                                    {cartproduct.product.product_discount &&
                                        <span id='discountcc'>
                                            [Price including discount <span>{cartproduct.product.product_discount}%</span>]
                                        </span>
                                    }
                                    <div id='quantity_adjusted'>{cartproduct.adjustment_message}</div>
                                </div>
                            </ul>
                        </div>
                    ))}
                    <div id='border'></div>
                    <div class="total"> Total : $ {cartproducts.total_cart_value}</div>
                    {cartproducts.tax_percent && <div class="tax"> Tax : % {cartproducts.tax_percent}</div>}
                    {fastD ?
                        <div class="cart-standard-shipping"> fast shipping applied : $ {cartproducts?.fast_shipping_charges}</div> :
                        <>
                            {cartproducts?.free_shipping ?
                                <div class="cart-free-shipping"> Free delivery applied</div> :
                                <div class="cart-standard-shipping"> standard_shipping_price : $ {cartproducts?.standard_shipping_price}</div>

                            }
                        </>
                    }
                    {cartproducts?.coupon_applied !== "N/A" && cartproducts?.coupon_applied?.discount_coupon && (
                        <div className="cart-free-shipping">Coupon applied: {cartproducts.coupon_applied.discount_coupon}</div>
                    )}
                    <div id='border'></div>
                    <div class="total" style={{ marginBottom: '1rem' }}> Sub Total : $ {cartproducts.subtotal_after_tax}</div>
                    <div id='extra'>
                        {cartproducts?.coupons &&
                            (<div id='varientDropdown' style={{ padding: '10px', marginBottom: '2px' }}>
                                <select
                                    value={selectedCoupon ? selectedCoupon.id : ''}
                                    onChange={(e) => {
                                        const selectedId = parseInt(e.target.value);
                                        const coupon = cartproducts?.coupons.find(v => v.id === selectedId);
                                        setSelectedCoupon(coupon);
                                    }}
                                >
                                    <option value="" disabled>Select a Coupon</option> {/* Default empty option */}
                                    {cartproducts?.coupons.map(coupon => (
                                        <option key={coupon.id} value={coupon.id}>
                                            {coupon.discount_coupon} - {coupon.discount_percent} %
                                        </option>
                                    ))}
                                </select>
                            </div>)}
                        <div className="cart-coupon">
                            <input
                                type="text"
                                placeholder="Enter Coupon Code"
                                value={selectedCoupon ? selectedCoupon.discount_coupon : ''} // You need to manage this state
                                id={selectedCoupon ? selectedCoupon.id : ''}
                                readOnly
                            />
                            <button onClick={applyCoupon}>Apply</button> {/* Function to apply the coupon */}
                        </div>
                        <label className="cart-fast-shipping">
                            <input
                                type="checkbox"
                                onChange={handleFastShippingChange} // Define this function to handle changes
                            />
                            Apply Fast Shipping: $ {cartproducts?.fast_shipping_charges}
                        </label>
                    </div>


                </section>

                {/* Payment Options */}
                <section className="checkout-payment-options">
                    <label className="cart-fast-shipping">
                        <input
                            type="radio"
                            name="paymentOption" // Same name groups the radio buttons
                            id='razorpay'
                            onChange={handlePayType} // Define this function to handle changes
                        />
                        Payment via
                        <Image src={razorpay} alt="Razorpay" width="70" className="payment-logo" />

                    </label><label className="cart-fast-shipping">
                        <input
                            type="radio"
                            name="paymentOption" // Same name groups the radio buttons
                            id='paypal'
                            onChange={handlePayType} // Define this function to handle changes
                        />
                        Payment via
                        <Image src={paypal} alt="PayPal" width="70" className="payment-logo" />

                    </label><label className="cart-fast-shipping">
                        <input
                            type="radio"
                            name="paymentOption" // Same name groups the radio buttons
                            id='payoneer'
                            onChange={handlePayType} // Define this function to handle changes
                        />
                        Payment via
                        <Image src={payoneer} alt="Payoneer" width="70" className="payment-logo" />

                    </label>
                    <button type="button" id="proceed-pay" onClick={submitPay} className="proceed-pay-button">Proceed to Pay</button>
                </section>
            </div>
        </div>
    );
};

export default CheckOut;
