import './App.scss';
import Header from './components/Mainpage/Header';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Content/Home';
import Landingpage from './components/Landingpage/Landingpage'
import Productshow from './components/Content/Productshow';
import Footer from './components/Footer/Footer';
import Showallreviews from './components/Content/Showallreviews';
import AboutUs from './components/Content/AboutUs';
import Search from './components/Search/Search';
import TAC from './components/Content/TAC';
import CheckOut from './components/CheckOut/CheckOut';
import CheckOutHeader from './components/CheckOut/CheckOutHeader';
import Check from './components/Private_routes/Check';
import Profile from './components/Content/Profile';
import Your_Orders from './components/Content/Your_Orders'
import Order from './components/Content/Order'
function App() {
  const location = useLocation();
  const isCheckOutPage = location.pathname.startsWith('/check/checkout');
  return (
    <>
      {!isCheckOutPage && <Header />}
      {isCheckOutPage && <CheckOutHeader />}
      <Routes>
        <Route path='/' element={<Landingpage />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/check/*' element={<Check />}>
          <Route path='checkoutpage' element={<CheckOut />} />
          <Route path='profile' element={<Profile />} />
          <Route path='profile/your-orders' element={<Your_Orders />} />
          <Route path='profile/your-orders/order/:order_id' element={<Order />} />
        </Route>
        <Route path='/tac' element={<TAC />} />
        <Route path='/productshow/:product_code' element={<Productshow />} />
        <Route path='/allproducts' element={<Home />} />
        <Route path='/category/:category_code' element={<Home />} />
        <Route path='/cut/:cut' element={<Home />} />
        <Route path='/shape/:shape' element={<Home />} />
        <Route path='/reviewshow/:product_code' element={<Showallreviews />} />
        <Route path='/search/:query' element={<Search />} />
      </Routes>
      {!isCheckOutPage && <Footer />}
    </>
  );
}

export default App;
