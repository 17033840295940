import React from 'react'
import { useState } from 'react';

const useCartContant = () => {
    const [cartproducts, setcartproducts] = useState({ cart_items: [], total_cart_value: 0 });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchCartProducts = async (coupon_id , fast_delivery) => {
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        try {
            const response = await fetch(`https://backend.amjgems.com/api/cart/items`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify({ user_id: parseInt(user_id) , coupon_id:coupon_id ? coupon_id : "null" , fast_delivery: fast_delivery ? fast_delivery : false}),
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setcartproducts(data);
            setError(null);
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
    };

    return { cartproducts, loading, error, fetchCartProducts };
}

export default useCartContant