import React, { useState, useEffect, useRef } from 'react';
import useCutShapes from '../Admin/useCutShapes';
import { Link } from 'react-router-dom';

import '../Admin/Admin.scss';

const Categorysection = () => {
  const { cuts, shapes, loading, error, fetchcutshapes } = useCutShapes();
  const [isCutsDropdownVisible, setIsCutsDropdownVisible] = useState(false);
  const [isShapesDropdownVisible, setIsShapesDropdownVisible] = useState(false);

  // Refs to detect clicks outside the dropdowns
  const cutsRef = useRef(null);
  const shapesRef = useRef(null);
  const dropdownsRef = useRef(null);

  // Show cuts dropdown
  const toggleCutsDropdown = () => setIsCutsDropdownVisible(true);

  // Show shapes dropdown
  const toggleShapesDropdown = () => setIsShapesDropdownVisible(true);

  // Hide cuts dropdown
  const hideCutsDropdown = () => setIsCutsDropdownVisible(false);

  // Hide shapes dropdown
  const hideShapesDropdown = () => setIsShapesDropdownVisible(false);

  // Handle click outside to close dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        cutsRef.current && !cutsRef.current.contains(event.target) &&
        shapesRef.current && !shapesRef.current.contains(event.target) &&
        dropdownsRef.current && !dropdownsRef.current.contains(event.target)
      ) {
        setIsCutsDropdownVisible(false);
        setIsShapesDropdownVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div id="categories">
        {/* All Products link */}
        <span>
          <Link className="link" to="/allproducts">All Products</Link>
        </span>

        {/* Cuts dropdown */}
        <span
          ref={cutsRef}
          onMouseEnter={() => {
            if (!isCutsDropdownVisible) {
              toggleCutsDropdown();
            }
            hideShapesDropdown();
          }}
          onMouseLeave={() => {
            hideCutsDropdown();
          }}
        >
          Cut's
        </span>

        {/* Shapes dropdown */}
        <span
          ref={shapesRef}
          onMouseEnter={() => {
            if (!isShapesDropdownVisible) {
              toggleShapesDropdown();
            }
            hideCutsDropdown();
          }}
          onMouseLeave={() => {
            hideShapesDropdown();
          }}
        >
          Shape's
        </span>
      </div>

      {/* Render cuts dropdown if visible */}
      {isCutsDropdownVisible && (
        <div
          ref={dropdownsRef}
          className="dropdown-menux"
          id="dropdown-menu-cuts"
          onMouseEnter={() => {
            toggleCutsDropdown();
          }}
          onMouseLeave={() => {
            hideCutsDropdown();
          }}
        >
          {cuts.map((cut, index) => (
            <span key={index}>
              <Link
                onClick={() => {
                  setIsCutsDropdownVisible(false);
                  setIsShapesDropdownVisible(false);
                }}
                className="link" to={`/cut/${cut.cut}`}>
                {cut.cut}
              </Link>
            </span>
          ))}
        </div>
      )}

      {/* Render shapes dropdown if visible */}
      {isShapesDropdownVisible && (
        <div
          ref={dropdownsRef}
          className="dropdown-menux"
          id="dropdown-menu-shapes"
          onMouseEnter={() => {
            toggleShapesDropdown();
          }}
          onMouseLeave={() => {
            hideShapesDropdown();
          }}
        >
          {shapes.map((shape, index) => (
            <span key={index}>
              <Link onClick={() => {
                setIsCutsDropdownVisible(false);
                setIsShapesDropdownVisible(false);
              }}
                className="link" to={`/shape/${shape.shape}`}>
                {shape.shape}
              </Link>
            </span>
          ))}
        </div>
      )}
    </>
  );
};

export default Categorysection;
