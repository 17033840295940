import React from 'react'
import './Landingpage.scss'
import { useState } from "react";
import { Carousel } from "react-bootstrap";
import useCarousel from '../Admin/useCarousel';
import useCategories from '../Admin/useCategories';
import Loader from '../Loader/Loader';
import Home from '../Content/Home';
import { Link } from 'react-router-dom';
import Categorysection from '../Mainpage/Categorysection';
import loginimage from '../../images/croped amj.png';

const Landingpage = () => {

    const { categories, loding, error, fetchcategories } = useCategories();
    const [index, setIndex] = useState(0);
    const { carousels, loading, errorc, fetchCarousel } = useCarousel();


    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    if (loading || loding) {
        // Render nothing or a loading indicator while the check is in progress
        return <div><Loader /></div>;
    }
    return (
        <div id='landingpage'>
            <Carousel activeIndex={index} onSelect={handleSelect}>
                {carousels.map((carousel, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            src={`https://backend.amjgems.com/${carousel.image_path}`}
                            alt="First slide"
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
            <section id='Detailslpage'>
                <div className="logoDetails"><img src={loginimage} alt="Login" /></div>
                <div id='DetailsContent' >
                    <h2>Amazing Gems And Jewellers</h2>
                    <div id='desclpage'>See all kinds of stones with superb Quality.</div>
                    <div id='desclpage'>Jaipur, Rajasthan</div>
                </div>


            </section>

            <section id='landingSec'>
                <div id='categoryPart'>
                    <div id='headinglpage' >
                        <h2>Categories</h2>
                        <div id='desclpage'>Browse categories.</div>
                    </div>
                    <div id='categoriesLinksDiv'>
                        <div id='linkdiv'><Link className='linkLandingpage' to={`/allproducts`}>All Products</Link></div>
                        {categories.map((category) => (
                            <div id='linkdiv' key={category.category_code}><Link className='linkLandingpage' to={`/category/${category.category_code}`}>{category.category_name}</Link></div>
                        ))}
                    </div>
                </div>
                <div>
                    
                    <Home />
                </div>
            </section>
        </div>
    )
}

export default Landingpage