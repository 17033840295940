// useproducts.js
import { useState } from 'react';

const useProducts = (filters={}) => {
  const [products, setproducts] = useState([]);
  const [ lastPage , setLastPage] = useState();
  const [ currentPage , setCurrentPage] = useState();
  const [load, setLoad] = useState(true);
  const [err, setErr] = useState(null);

  const fetchproducts = async (pageNumber=1) => {
    try {
      const response = await fetch(`https://backend.amjgems.com/api/products/fetch?page=${pageNumber}` , {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(filters),
      }); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setproducts(data.data);
      setLastPage(data.last_page);
      setCurrentPage(data.current_page)
      setErr(null);
    } catch (error) {
      console.log(error);
      setErr(error.message);
    } finally {
      setLoad(false);
    }
  };

  return { products, currentPage , lastPage , load, err, fetchproducts };
};

export default useProducts;